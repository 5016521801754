<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t('configure.title') }}</ion-title>
        <ion-buttons v-if="hasRearCamera && hasFrontCamera" slot="end">
          <ion-button @click="switchCamera">
            <ion-icon slot="icon-only" name="camera-reverse"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true"
      ><qr-stream :camera="camera" @init="onInit" @decode="onDecode">
      </qr-stream>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  alertController,
  IonButton,
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { QrStream } from 'vue3-qr-reader'
import useQrStorage from '@/composables/storage-service'

export default defineComponent({
  name: 'Configure',
  components: {
    IonButton,
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    QrStream
  },
  data() {
    return {
      router: useRouter(),
      qrStorage: useQrStorage(),
      camera: 'rear',
      hasFrontCamera: true,
      hasRearCamera: true
    }
  },
  methods: {
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (this.camera == 'front') {
          this.hasFrontCamera = false
        }
        if (this.camera == 'rear') {
          this.hasRearCamera = false
        }
        if (!this.hasFrontCamera && !this.hasRearCamera) {
          const alert = await alertController.create({
            header: this.$t('errors.qrCodeScan'),
            message: error.message,
            buttons: ['OK']
          })
          alert.present()
          this.router.back()
        } else {
          console.log('Switching camera')
          this.$nextTick(() => {
            this.switchCamera()
          })
        }
      }
    },
    switchCamera() {
      if (this.camera == 'rear' && this.hasFrontCamera) {
        this.camera = 'front'
      } else if (this.camera == 'front' && this.hasRearCamera) {
        this.camera = 'rear'
      }
    },
    onDecode(data) {
      this.qrStorage.save(data)
      this.router.back()
    }
  }
})
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}
</style>
